.arrow_box {
    position: relative;
    background: #555;
    border: 2px solid #000000;
  }
  .arrow_box:after, .arrow_box:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .arrow_box:after {
    border-color: rgba(85, 85, 85, 0);
    border-right-color: #555;
    border-width: 10px;
    margin-top: -10px;
  }
  .arrow_box:before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #000000;
    border-width: 13px;
    margin-top: -13px;
  }